import React from 'react'

import { Emails, ExternalLinks } from 'constants/links'

import {
  Attention,
  Caption,
  Container,
  Paragraph,
  Subtitle,
  Title,
} from './styles'

type Content = {
  title: string
  id: string
}

export type TermsTableOfContent = {
  [key: string]: Content
}

export const TERMS_TABLE_OF_CONTENT_BY_ID = {
  changesToTheAgreement: {
    title: 'Changes to the Agreement',
    id: 'changesToTheAgreement',
  },
  acknowledgementRegardingMnwTokens: {
    title: 'Acknowledgement Regarding MNW Tokens',
    id: 'acknowledgementRegardingMnwTokens',
  },
  masternodeServices: {
    title: 'Masternode Services, Licensing & Documentation',
    id: 'masternodeServices',
  },
  nodeOperatorGrantOfRightToMasternode: {
    title: 'Node Operator Grant of Right to Masternode',
    id: 'nodeOperatorGrantOfRightToMasternode',
  },
  nodeOperatorResponsibilities: {
    title: 'Node Operator Responsibilities',
    id: 'nodeOperatorResponsibilities',
  },
  nodeFees: {
    title: 'Node Fees; Payment',
    id: 'nodeFees',
  },
  penaltiesForEarlyUnstake: {
    title: 'Penalties for Early Unstaking',
    id: 'penaltiesForEarlyUnstake',
  },
  disqualification: {
    title: 'Disqualification',
    id: 'disqualification',
  },
  programInstructions: {
    title: 'Registration Instructions',
    id: 'programInstructions',
  },
  programPeriod: {
    title: 'Program Period',
    id: 'programPeriod',
  },
  prohibitedJurisdictions: {
    title: 'Prohibited Jurisdictions',
    id: 'prohibitedJurisdictions',
  },
  restrictions: {
    title: 'Restrictions',
    id: 'restrictions',
  },
  representations: {
    title: 'Representations',
    id: 'representations',
  },
  disclaimers: {
    title: 'Disclaimers',
    id: 'disclaimers',
  },
  termTermination: {
    title: 'Term; Termination',
    id: 'termTermination',
  },
  ownership: {
    title: 'Ownership',
    id: 'ownership',
  },
  confidentiality: {
    title: 'Confidentiality',
    id: 'confidentiality',
  },
  noWarranty: {
    title: 'No Warranty',
    id: 'noWarranty',
  },
  limitationOnLiability: {
    title: 'Limitation on Liability',
    id: 'limitationOnLiability',
  },
  indemnification: {
    title: 'Indemnification',
    id: 'indemnification',
  },
  assignment: {
    title: 'Assignment',
    id: 'assignment',
  },
  governingLawAndJurisdiction: {
    title: 'Governing Law and Jurisdiction',
    id: 'governingLawAndJurisdiction',
  },
  relationship: {
    title: 'Relationship',
    id: 'relationship',
  },
  waiverAndSeverability: {
    title: 'Waiver and Severability',
    id: 'waiverAndSeverability',
  },
  survival: {
    title: 'Survival',
    id: 'survival',
  },
  entireAgreement: {
    title: 'Entire Agreement',
    id: 'entireAgreement',
  },
  notice: {
    title: 'Notice',
    id: 'notice',
  },
  rulesOfQuest: {
    title: 'Quest Competition Official Rules',
    id: 'rulesOfQuest',
  },
  quest_acceptanceRules: {
    title: 'Acceptance of Rules',
    id: 'quest_acceptanceRules',
  },
  questProhibitedJurisdictions: {
    title: 'Prohibited Jurisdictions',
    id: 'quest_prohibitedJurisdictions',
  },
  questCompetitionPeriod: {
    title: 'Competition Period',
    id: 'quest_competitionPeriod',
  },
  questRegistration: {
    title: 'Registration',
    id: 'quest_registration',
  },
  quests: {
    title: 'Quests',
    id: 'quest_quests',
  },
  questSeasons: {
    title: 'Seasons',
    id: 'quest_seasons',
  },
  questBrackets: {
    title: 'Brackets',
    id: 'quest_brackets',
  },
  questPrizePools: {
    title: 'Prize Pools',
    id: 'quest_prizePools',
  },
  questParticipateOnMobile: {
    title: 'Participation Using a Mobile Device',
    id: 'quest_participateOnMobile',
  },
  questNoPurchaseNecessary: {
    title: 'No Purchase Necessary',
    id: 'quest_noPurchaseNecessary',
  },
  questNotification: {
    title: 'Notification',
    id: 'quest_notification',
  },
  questDisqualification: {
    title: 'Disqualification',
    id: 'quest_disqualification',
  },
  questDisclaimers: {
    title: 'Disclaimers',
    id: 'quest_disclaimers',
  },
  questLimitationOfLiability: {
    title: 'Limitation of Liability',
    id: 'quest_limitationOfLiability',
  },
  questGoverningLawAndJurisdiction: {
    title: 'Governing Law and Jurisdiction',
    id: 'quest_governingLawAndJurisdiction',
  },
  questArbitration: {
    title: 'Arbitration',
    id: 'quest_arbitration',
  },
  questLimitation: {
    title: 'Limitation on Time to File Claims',
    id: 'quest_limitation',
  },
  questWaiverAndSeverability: {
    title: 'Waiver and Severability',
    id: 'quest_waiverAndSeverability',
  },
  questEntireAgreement: {
    title: 'Entire Agreement',
    id: 'quest_entireAgreement',
  },
}

function TermsLayout() {
  return (
    <Container>
      <Title sx={{ marginBottom: '12px' }}>
        Trust.Supply
        <br />
        Masternode Operator Agreement
      </Title>
      <Caption sx={{ marginBottom: '48px' }}>Last Updated: 2024-07-17</Caption>
      <Paragraph sx={{ marginBottom: '24px' }}>
        <span>
          This Trust.Supply Masternode Operator Agreement (the “<b>Agreement</b>
          ”) is a legally binding agreement between you (“
          <b>Node Operator</b>,” “<b>you</b>” or “<b>your</b>”) and
          Morpheus.Network Inc., a Seychelles international business company
          with registered office at Suite 1, Commercial House One Eden Island,
          Seychelles (“
          <b>Morpheus.Network,</b>” “<b>Company,</b>” “<b>us,</b>” “<b>we</b>”
          or “<b>our</b>”), and applies to your participation in the
          Trust.Supply Masternode Program (the “<b>Program</b>”). You understand
          and agree that by accepting the terms of this Agreement, either by
          clicking to signify acceptance, or by downloading, installing, running
          and/or using the applicable Masternode software (the “
          <b>Node Software</b>”), you agree to be bound by the terms of this
          Agreement effective as of the date that you take the earliest of one
          of the foregoing actions. You further represent and warrant that you
          are at least 18 years old and have the right and authority to enter
          into and comply with the terms of this Agreement.
        </span>
      </Paragraph>

      <Attention sx={{ marginBottom: '48px' }}>
        PLEASE READ THE TERMS OF THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU
        UNDERSTAND EACH PROVISION. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF
        THIS AGREEMENT, DO NOT CHECK THE ACCEPTANCE BOX, AND DO NOT PARTICIPATE
        IN THE PROGRAM OR OPERATE A MASTERNODE.
      </Attention>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.changesToTheAgreement.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.changesToTheAgreement.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          Morpheus.Network may revise and update this Agreement from time to
          time in its sole discretion. All changes are effective immediately
          when posted and shall apply to your participation in the Program
          thereafter.
          <br />
          <br />
          Your continued participation in the Program following the posting of a
          revised Agreement means that you accept and agree to any changes.
          Morpheus will use reasonable efforts to notify you (e.g., via email)
          in the event that any changes are made but you are expected to check
          this Agreement from time to time so you are aware of any changes, as
          they are binding on you.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.acknowledgementRegardingMnwTokens.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.acknowledgementRegardingMnwTokens.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          By participating in the Program, you acknowledge that you understand
          and accept that the purchase, ownership, receipt, or possession of MNW
          tokens carry no rights, express or implied, other than the right to
          use such tokens as a means to participate, interact or transact on the
          Morpheus.Network supply chain middleware platform (the “
          <b>Platform</b>
          ”), of which this Program is a part. In particular, you understand and
          accept that neither MNW tokens nor your participation in the Program
          represents or confers any ownership right or stake, share, security,
          or equivalent rights, or any right to receive future revenue shares,
          intellectual property rights or any other form of participation in or
          relating to Morpheus.Network and its corporate affiliates, other than
          any rights relating to the provision and receipt of services from
          Morpheus made available to you directly on the Platform. MNW tokens
          are not intended to be utilized outside the Platform nor as a digital
          currency, security, commodity, or any kind of financial instrument,
          and Morpheus.Network shall not be liable to you for any decrease in
          value of MNW tokens or any other damages that you may incur as a
          result of using or purchasing MNW tokens for anything besides their
          intended use on the Platform.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.masternodeServices.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.masternodeServices.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          Morpheus.Network shall provide access to the Platform to its customers
          pursuant to a separate End User License Agreement. You may implement
          and provide Masternode Services (as defined below) to
          Morpheus.Network’s customers who access the Platform and choose to
          utilize the Trust.Supply Validator Network (the “
          <b>Validator Network</b>” ) pursuant to the terms hereof during the
          Term of this Agreement. The Validator Network include the features and
          functionality applicable to the version of the Node Software installed
          by Node Operator. Company may update the content, functionality, and
          user interface of the Validator Network from time to time in its sole
          discretion.
          <br />
          <br />
          In connection with providing the Masternode Services, Morpheus.Network
          grants you a non-exclusive, non-sublicensable, non-transferable right
          to participate as a Node Operator on the Validator Network solely for
          your internal business purposes subject to the limitations set forth
          in this Agreement.
          <br />
          <br />
          Any Node Software provided to you shall consist of open-source code
          that is made available pursuant to the terms of an open-source license
          agreement(s) (the “<b>Open-Source License(s)</b>”) which shall be
          posted online and made accessible to you before you download and
          install the Node Software. Your operation of a masternode on the
          Validator Network is conditioned upon your compliance at all times
          with the terms of all applicable Open-Source License(s), including
          without limitation all provisions governing access to source code,
          modification, and/or reverse engineering.
          <br />
          <br />
          You are responsible for complying with any applicable documentation,
          meaning any information that describes the Masternode Services, the
          Validator Network, provides instructions or recommendations related to
          the configuration and/or use of the Node Software, or otherwise
          informs Morpheus.Network’s customers of the intended use of the
          Validator Network, including, but not limited to content provided
          directly to users or published at{' '}
          <a
            href={ExternalLinks.MorpheusNetworkRoot}
            rel="noreferrer"
            target={'_blank'}
          >
            https://morpheus.network
          </a>
          ,{' '}
          <a
            href={ExternalLinks.TrustSupplyRoot}
            rel="noreferrer"
            target={'_blank'}
          >
            https://trust.supply
          </a>{' '}
          or otherwise made available in conjunction with the Validator Network
          or Node Software (“
          <b>Documentation</b>”) and for satisfying all technical requirements
          of the Node Software, including any requirements set forth in the
          Documentation for ensuring that the Node Software performs properly.
        </span>
      </Paragraph>

      <Subtitle
        id={
          TERMS_TABLE_OF_CONTENT_BY_ID.nodeOperatorGrantOfRightToMasternode.id
        }
        sx={{ marginBottom: '24px' }}
      >
        {
          TERMS_TABLE_OF_CONTENT_BY_ID.nodeOperatorGrantOfRightToMasternode
            .title
        }
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          By participating in the Program, installing and configuring the Node
          Software, staking the requisite number of MNW tokens to a masternode,
          and joining the Validation Network, you hereby grant to Morpheus and
          its customers the irrevocable right to access and use your masternode
          which shall provide the following services (collectively, the “
          <b>Masternode Services</b>”):
          <br />
          <ul>
            <li>
              <b>
                Validate data queries that have been distributed to your
                validator pool and allocated to your masternode for validation
              </b>
            </li>
          </ul>
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.nodeOperatorResponsibilities.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.nodeOperatorResponsibilities.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        At all times during the terms of this Agreement or during your
        participation in the Program, whichever is longer, you agree to all of
        the following:
        <br />
        <br />
        <ul>
          <li>
            To comply with the terms of this Agreement, Morpheus.Network’s Terms
            of Use and Privacy Policy and any other terms and conditions
            required by Morpheus.Network in connection herewith, the Open-Source
            License(s), and the terms of all other agreements to which you are a
            party in connection with your performance under this Agreement
            including, without limitation, any agreement you have with a
            third-party Internet service provider;
          </li>
          <li>
            To operate the masternode in strict compliance with terms of this
            Agreement and to not take any action not expressly authorized in
            this Agreement;
          </li>
          <li>
            To not attempt to circumvent or manipulate the performance of any
            masternode you operate, and not otherwise interfere with the
            operation of the Validator Network or the Platform;
          </li>
          <li>
            To maintain your masternode so that it meets the following minimum
            requirements (“<b>Minimum Node Requirements</b>”) at all times:
          </li>
          <ul>
            <li>Have at least 1,800 MNW staked to each masternode;</li>
            <li>
              Have a minimum of one (1) hard drive and one (1) processor core
              dedicated to each masternode;
            </li>
            <li>
              Have a minimum of 500 MB of available hard drive space per
              masternode;
            </li>
            <li>
              Have the masternode on an Internet connection with at least 10
              Mbps bandwidth upstream and 25 Mbps bandwidth downstream;
            </li>
            <li>
              Keep the masternode online and operational no less than 99% of the
              time per month;
            </li>
            <li>
              Meet all performance requirements referred to in this Agreement,
              as well as any performance requirements set forth in the
              Documentation or in other instructions made available with the
              Node Software or otherwise hereunder;
            </li>
            <li>
              Be connected to the Internet to enable Morpheus.Network and its
              customers to access the Validator Network with a minimum of 99%
              availability as measured on a monthly basis;
            </li>
          </ul>
          <li>
            To retain administrative control over to whom you grant access to
            the applicable masternode. You are responsible for maintaining the
            security of the masternode including without limitation all
            applicable credentials used to access the masternode;
          </li>
          <li>
            To be willing to affirm and/or reaffirm your agreement to the terms
            of this Agreement, and in such case, your continued use of the Node
            Software is contingent upon your promptly providing such affirmation
            as requested by Morpheus.Network
          </li>
        </ul>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.nodeFees.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.nodeFees.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          Subject to the terms of this Agreement, in full and final payment for
          your provision of the Masternode Services hereunder, Morpheus.Network
          will pay you based upon the amount of MNW tokens staked to each
          masternode (“<b>Node Fees</b>”). Morpheus.Network will pay the Node
          Fees in MNW.
          <br />
          Node Operators can choose to stake their MNW tokens for fixed terms of
          3, 6, or 12 months. The reward per validated transaction varies based
          on the term of the stake as follows (note that these rewards are gross
          amounts, and net earnings are calculated after deducting gas fees
          necessary for recording validations on the public blockchain):
          <br />
          <ul>
            <li>3 months: 0.06 MNW per transaction</li>
            <li>6 months: 0.08 MNW per transaction</li>
            <li>12 months: 0.1 MNW per transaction</li>
          </ul>
          <br />
          Node Operators must stake the minimum number of MNW tokens and meet
          all the Minimum Node Requirements for the entire term of the stake
          selected above in order to be eligible to receive transaction rewards.
          Payment of transaction rewards shall be made at the end of the
          selected term and shall be based on the number of transactions
          validated and the term of the stake less any gas fees necessary for
          recording those validations on the public blockchain.
          <br />
          <br />
          Node Operator is not authorized to incur any expenses on behalf of
          Morpheus.Network and is solely responsible for all expenses incurred
          in connection with this Agreement. Morpheus.Network shall not
          reimburse Node Operator for any expenses hereunder.
          <br />
          <br />
          You are responsible for determining what, if any, taxes apply to your
          participation in the Program, including any sales, use, value added,
          and similar taxes. It is also your responsibility to withhold,
          collect, report, and remit the correct taxes to the appropriate tax
          authorities. Morpheus.Network is not responsible for withholding,
          collecting, reporting, or remitting any sales, use, value added, or
          other tax arising from your participation in the Program
          <br />
          <br />
          Morpheus.Network reserves the right to modify the Node Fees at any
          time, including the rate of payment and the Masternode Services for
          which Node Fees are paid.
          <br />
          <br />
          In addition to its other rights and remedies under this Agreement,
          Node Operators forfeit any right to compensation under this Agreement
          if Node Operator breaches any term hereof.
          <br />
          <br />
          You must provide a valid ERC-20 compatible wallet address (your “
          <b>Payment Address</b>”) in order to receive compensation in the form
          of MNW tokens.
          <br />
          <br />
          If your Payment Address cannot be verified, is invalid, or is not
          otherwise acceptable, then in addition to any other rights and
          remedies available to Morpheus.Network hereunder, your right to
          receive payment of the Node Fees may be suspended automatically and
          indefinitely.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.penaltiesForEarlyUnstake.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.penaltiesForEarlyUnstake.title}
      </Subtitle>

      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          If you decide to unstake your MNW tokens before the end of the chosen
          term, penalties are applied based on the remaining time. See the{' '}
          <a
            download="TrustSupply_Masternodes_Lightpaper.pdf"
            href={'/files/TrustSupply_Masternodes_Lightpaper.pdf'}
          >
            Trust.Supply Masternodes Lightpaper
          </a>{' '}
          for more details about how penalties are calculated.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.disqualification.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.disqualification.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          If you operate your masternode in a manner that violates any term of
          this Agreement, including without limitation any of the
          representations or warranties set forth herein, your masternode may,
          in Morpheus.Network’s sole and absolute discretion, be subject to
          Disqualification. For purposes hereof, the term “
          <b>Disqualification</b>” means the applicable masternode is
          permanently banned from communicating with the Validator Network, is
          entitled to no future payment of Node Fees, and immediately forfeits
          any amount held in escrow. Following Disqualification, in order to
          resume operation with the Validator Network, you will be required to
          create a new masternode with a new masternode identification and
          signed identity and all other requirements set forth in this Agreement
          must be met by the Node Operator and the masternode.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.programInstructions.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.programInstructions.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        After accepting this Agreement, Morpheus.Network’s Terms of Use and
        Privacy Policy, you will need to be verified by our partner, Sum &
        Substance, for KYC/AML Compliance Testing. In order to complete the
        verification process, you will need to provide a government issued
        identification, ensuring the image quality is clear, and once that has
        been uploaded you will be required to pass a liveliness check via your
        phone.
        <br />
        <br />
        Once you have been notified via email that your KYC has passed
        successfully, you can move on to staking to run masternodes via the
        dashboard on the Trust.Supply website. Specific instructions for
        downloading, installing and configuring the Node Software shall be
        provided to you upon successful completion of the registration process.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.programPeriod.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.programPeriod.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        The start and end date of the Program Period shall be provided to you
        following your successful completion of the registration process. This
        period may be extended or shortened by Morpheus.Network in its sole
        discretion.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.prohibitedJurisdictions.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.prohibitedJurisdictions.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        You will not be permitted to participate in the Program if you are a
        citizen or resident of any of the following jurisdictions (the
        “Prohibited Jurisdictions”):
        <ul>
          <li>Belarus</li>
          <li>Central African Republic</li>
          <li>Canada</li>
          <li>China</li>
          <li>Cuba</li>
          <li>Democratic Republic of the Congo</li>
          <li>Ethiopia</li>
          <li>Iran</li>
          <li>Iraq</li>
          <li>Lebanon</li>
          <li>Libya</li>
          <li>Mali</li>
          <li>Myanmar (Burma)</li>
          <li>Nicaragua</li>
          <li>North Korea</li>
          <li>Russia</li>
          <li>Somalia</li>
          <li>South Sudan</li>
          <li>Sudan</li>
          <li>Syria</li>
          <li>Ukraine (Crimea)</li>
          <li>United States of America and its territories</li>
          <li>Venezuela</li>
          <li>Yemen</li>
          <li>Zimbabwe</li>
        </ul>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.restrictions.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.restrictions.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        You will operate the masternode in strict accordance with the terms of
        this Agreement and in no other manner. Without limiting the generality
        of the foregoing, you agree that you will not:
        <ul>
          <li>
            rent, sublicense, re-sell, assign, transfer, distribute, timeshare,
            or similarly exploit any masternode;
          </li>
          <li>
            reverse engineer, copy, modify, adapt, hack the Validator Network or
            the Platform, or otherwise attempt to gain unauthorized access to
            the Validator Network or the Platform;
          </li>
          <li>
            access the Validator Network, Documentation, or any Confidential
            Information to build a competitive product or service;
          </li>
          <li>
            alter or remove, or permit any third party to alter or remove, any
            proprietary trademark or copyright markings incorporated in, marked
            on, or affixed to the Validator Network or the Platform;
          </li>
          <li>
            allow masternodes to be shared or used by anyone other than Node
            Operator;
          </li>
          <li>
            access or use the Validator Network in violation of applicable laws
            or in a manner that interferes with or disrupts the integrity or
            performance of the Platform (or the data contained therein);
          </li>
          <li>
            operate more masternodes than permitted based on your approved
            participation in the Program;
          </li>
          <li>
            operate a masternode that does not meet all of the Minimum Node
            Requirements;
          </li>
          <li>
            disconnect a masternode or otherwise purposefully render it
            “offline” for any reason other than Validator Network-required
            maintenance or Node Software updates;
          </li>
          <li>
            manipulate or otherwise attempt to bypass, change, or update any
            values related to uptime detection outside the programmatic
            operation of the Node Software;
          </li>
          <li>
            manipulate or alter the default behavior of the masternode to
            artificially increase or decrease your Node Fees;
          </li>
          <li>
            manipulate network responses to any request with unauthorized intent
            to change the cryptographic signatures, masternode identification,
            or Payment Address; or
          </li>
          <li>
            in any other way attempt to interfere, impede, alter, or otherwise
            interact in any manner not expressly authorized hereunder with the
            Validator Network or the operation of any other masternodes.
          </li>
        </ul>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.representations.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.representations.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        Prior to participating in the Program, you represent and warrant to
        Morpheus.Network that all of the following are true:
        <ul>
          <li>
            You have been pre-approved by Morpheus.Network to participate in the
            Program;
          </li>
          <li>
            You are the lawful owner of any MNW tokens that you intend to stake
            to one or more masternodes;
          </li>
          <li>
            You are participating in the Program to help Morpheus.Network
            validate transactions on the Platform and not for investment,
            speculative or other financial purposes;
          </li>
          <li>
            You have read and agree to the Morpheus.Network Terms of Use,
            available at{' '}
            <a
              href={ExternalLinks.MorpheusNetworkTermsOfUse}
              rel="noreferrer"
              target={'_blank'}
            >
              https://morpheus.network/terms-of-use/
            </a>
            ;
          </li>
          <li>
            You have read and agree to the Morpheus.Network Privacy Policy,
            available at{' '}
            <a
              href={ExternalLinks.MorpheusNetworkPrivacyPolicy}
              rel="noreferrer"
              target={'_blank'}
            >
              https://morpheus.network/privacy-policy/
            </a>
            ;
          </li>
          <li>
            You have full power and authority to enter into this Agreement and
            comply with all terms hereof, and doing so will not conflict with
            any obligation you may owe to any third party;
          </li>
          <li>
            You will at all times comply with all applicable laws, orders,
            rules, and regulations currently in effect or that may come into
            effect during the term of this Agreement, including but not limited
            to those regarding data privacy and protection;
          </li>
          <li>
            You are authorized to receive the Node Fees as set forth in this
            Agreement;
          </li>
          <li>
            You are not a citizen or resident of any Prohibited Jurisdiction;
          </li>
          <li>
            You are not participating in the Program in an effort to evade any
            international anti-money laundering or terrorist financing laws or
            regulations;
          </li>
          <li>
            You are not assisting anyone else in evading or attempting to evade
            any international anti-money laundering or terrorist financing laws
            or regulations; and
          </li>
          <li>
            Your participation in the Program does not violate any laws or
            regulations in any applicable jurisdiction.
          </li>
        </ul>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.disclaimers.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.disclaimers.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        By participating in the Program, you acknowledge and agree to all of the
        following:
        <ul>
          <li>
            Morpheus.Network shall not be liable for any losses you incur as a
            result of any of the following:
            <ul>
              <li>
                Failing to stake the requisite number of MNW tokens to a
                masternode;
              </li>
              <li>
                Staking your MNW tokens to the wrong contract address as a
                result of user error;
              </li>
              <li>
                Staking your MNW tokens from an exchange hosted wallet or other
                service where you do not control the private key;
              </li>
              <li>
                Failing to ensure that your masternode meets the Minimum Node
                Requirements;
              </li>
              <li>Failing to adhere to the terms of this Agreement;</li>
              <li>
                Failing to fulfill any of the other requirements of the Program;
              </li>
              <li>
                Staking your MNW tokens to a fraudulent address posted on a scam
                or phishing website;
              </li>
              <li>
                Losing access to your wallet as a result of lost credentials; or
              </li>
              <li>
                Technical failures outside the control of Morpheus.Network
                (e.g., network failures, wallet failures, protocol failures,
                etc.).
              </li>
            </ul>
            <li>
              Morpheus.Network is not responsible for any third-party wallet
              that you use to participate in the Program and makes no guarantee
              of any wallet’s functionality, security, or availability.
            </li>
          </li>
        </ul>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.termTermination.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.termTermination.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        This Agreement shall be effective as of the date you first applied to
        participate in the Program and will continue for the duration of the
        Program Period or until terminated per the terms below:
        <ul>
          <li>
            Either party may terminate this Agreement immediately without notice
            to the other party.
          </li>
          <li>
            If Morpheus.Network terminates this Agreement for your breach of
            this Agreement, Morpheus.Network will have no obligation to pay any
            unpaid Node Fees.
          </li>
          <li>
            If Morpheus.Network terminates this Agreement for any reason besides
            your breach of this Agreement, Morpheus.Network may pay you via the
            Payment Address any unpaid Node Fees due and payable to you
            hereunder.
          </li>
        </ul>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.ownership.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.ownership.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          Except for Node Software subject to the Open-Source License(s), and
          except for any rights expressly granted under this Agreement,
          Morpheus.Network and its licensors own and shall retain all right,
          title, and interest in and to the Platform (including any
          improvements, enhancements, customizations, and modifications
          thereto), related documentation, and Morpheus.Network’s Confidential
          Information, including, without limitation, all related intellectual
          property rights therein. No rights are granted to Node Operator
          hereunder other than as expressly set forth in this Agreement.
          <br />
          <br />
          Morpheus.Network shall have a royalty-free, worldwide, transferable,
          sublicensable, irrevocable, perpetual license to use or incorporate
          into the Platform any suggestions, ideas, enhancement requests,
          feedback, recommendations or other information provided by Node
          Operator relating to the features, functionality, or operation thereof
          (“<b>Feedback</b>
          ”). Morpheus.Network shall have no obligation to use Feedback, and
          Node Operator shall have no obligation to provide Feedback.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.confidentiality.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.confidentiality.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          In connection with this Agreement, each Party (as the “
          <b>Disclosing Party</b>”)may disclose or make available Confidential
          Information to the other Party (as the “<b>Receiving Party</b>”).
          Subject to any exclusions identified below, “
          <b>Confidential Information</b>” means information in any form or
          medium (whether oral, written, electronic or other) that: (i) if
          disclosed in writing or other tangible form or medium, is marked
          “confidential” or “proprietary;” (ii) if disclosed orally or in other
          intangible form or medium, is identified by the Disclosing Party as
          confidential or proprietary when disclosed and summarized and marked
          “confidential” or “proprietary” in writing by the Disclosing Party
          following disclosure; or (iii) due to the nature of its subject matter
          or the circumstances surrounding its disclosure, would reasonably be
          understood to be confidential or proprietary.
          <br />
          <br />
          By example, and without limitation, Confidential Information includes
          any and all non-public information that relates to the actual or
          anticipated business and/or products, research or development of the
          Disclosing Party, or to the Disclosing Party’s technical data, trade
          secrets, or know-how, including, but not limited to, research, product
          plans, or other information regarding the Disclosing Party’s products
          or services and markets therefor, customer lists and customers,
          software, developments, inventions, processes, formulas, technology,
          designs, drawings, engineering, hardware configuration information,
          marketing, finances, and other business information.
          <br />
          <br />
          Confidential Information does not include information that: (i) was
          rightfully known to the Receiving Party without restriction on use or
          disclosure prior to such information being disclosed or made available
          to the Receiving Party in connection with this Agreement; (ii) was or
          becomes generally known by the public other than by the Receiving
          Party’s noncompliance with this Agreement; (iii) was or is received by
          the Receiving Party on a non-confidential basis from a third party
          that was not or is not, at the time of such receipt, under any
          obligation to maintain its confidentiality; or (iv) is Feedback.
          <br />
          <br />
          As a condition to being provided with any disclosure of or access to
          Confidential Information, the Receiving Party shall: (i) not access or
          use Confidential Information other than as necessary to exercise its
          rights or perform its obligations under and in accordance with this
          Agreement; (ii) except as may be permitted under the paragraph dealing
          with compelled disclosures below, not disclose or permit access to
          Confidential Information other than its employees or agents who: (A)
          need to know such Confidential Information for purposes of the
          Receiving Party’s exercise of its rights or performance of its
          obligations under and in accordance with this Agreement; and (B) have
          been informed of the confidential nature of the Confidential
          Information and the Receiving Party’s obligations under this
          Agreement; (iii) safeguard the Confidential Information from
          unauthorized use, access or disclosure using at least the degree of
          care it uses to protect its similarly sensitive information and in no
          event less than a reasonable degree of care; (iv) promptly notify the
          Disclosing Party of any unauthorized use or disclosure of Confidential
          Information and cooperate with Disclosing Party to prevent further
          unauthorized use or disclosure; and (v) ensure its employees and
          agents comply with and be responsible and liable for any
          non-compliance with the terms of this Agreement.
          <br />
          <br />
          If the Receiving Party is compelled by applicable law to disclose any
          Confidential Information then, to the extent permitted by applicable
          law, the Receiving Party will: (i) promptly, and prior to such
          disclosure, notify the Disclosing Party in writing of such requirement
          so that the Disclosing Party can seek a protective order or other
          remedy or waive its rights to confidentiality under this Agreement;
          and (ii) provide reasonable assistance to the Disclosing Party in
          opposing such disclosure or seeking a protective order or other
          limitations on disclosure. If the Disclosing Party waives compliance
          or, after providing the notice and assistance required under this
          Agreement, the Receiving Party remains required by law to disclose any
          Confidential Information, the Receiving Party will disclose only that
          portion of the Confidential Information that the Receiving Party is
          legally required to disclose.
        </span>
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.noWarranty.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.noWarranty.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        THE PROGRAM IS BEING OFFERED ON AN “AS IS” AND “AS AVAILABLE” BASIS
        WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR
        STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
        MORPHEUS.NETWORK SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES AS TO THE
        NODE SOFTWARE AND THE OPERATION OF THE MASTERNODES INCLUDING IMPLIED
        WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
        AND/OR NON-INFRINGEMENT. MORPHEUS.NETWORK MAKES NO WARRANTY THAT THE
        NODE SOFTWARE, THE VALIDATOR NETWORK OR THE PLATFORM WILL BE
        UNINTERRUPTED, ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, VIRUS
        FREE, OR FREE OF MALICIOUS CODE OR HARMFUL COMPONENTS, OR THAT DEFECTS
        WILL BE CORRECTED. MORPHEUS.NETWORK FURTHER MAKES NO REPRESENTATIONS OR
        WARRANTIES THAT YOUR CONNECTION TO YOUR WALLET OR THE ETHEREUM NETWORK
        WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE. YOU ALSO
        UNDERSTAND AND AGREE THAT TRANSACTIONS USING BLOCKCHAIN TECHNOLOGY, SUCH
        AS THOSE INVOLVING OPERATION OF THE MASTERNODES, ARE AT RISK TO MULTIPLE
        POTENTIAL FAILURES, INCLUDING HIGH VOLUME, COMPUTER FAILURE, BLOCKCHAIN
        FAILURE OF ANY KIND, USER FAILURE, TOKEN THEFT, HACKING AND
        TELECOMMUNICATIONS OR INTERNET FAILURE OR DISRUPTION. MORPHEUS.NETWORK
        IS NOT RESPONSIBLE FOR ANY LOSS OF DATA, TOKENS OR OTHER CRYPTOCURRENCY,
        HARDWARE OR SOFTWARE RESULTING FROM ANY TYPES OF FAILURES, THEFT OR
        HACK. HACK.
        <br />
        <br />
        Some jurisdictions do not allow the exclusion of certain warranties or
        disclaimer of implied terms in contracts with consumers, so some or all
        of the exclusions of warranties and disclaimers in the preceding
        paragraph may not apply to you.
        <br />
        <br />
        Except as explicitly set forth herein, Morpheus.Network makes no
        representations that the operation of a masternode is permissible in any
        particular jurisdiction. Node Operators engaging with the Validator
        Network from any jurisdictions do so at their own risk and are
        responsible for compliance with local laws.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.limitationOnLiability.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.limitationOnLiability.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        IN NO EVENT WILL MORPHEUS.NETWORK OR ANY OF ITS CORPORATE AFFILIATES BE
        LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
        EXEMPLARY DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOSS OF
        REVENUE, INCOME OR PROFITS; UNREALIZED GAIN OR SAVINGS; LOSS OF USE OR
        DATA; OR DAMAGES FOR BUSINESS INTERRUPTION, OR LOSS OF TOKENS) ARISING
        OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE NODE SOFTWARE, THE
        VALIDATOR NETWORK, THE MASTERNODES OR THE USE OF, OR INABILITY TO USE,
        THE MASTERNODES, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN
        CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, SIMPLE NEGLIGENCE,
        WHETHER ACTIVE, PASSIVE OR IMPUTED), OR INFRINGEMENT OR ANY OTHER LEGAL
        OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE
        FORESEEABLE); AND
        <br />
        <br />
        IN NO EVENT WILL THE AGGREGATE LIABILITY OF MORPHEUS.NETWORK OR ANY OF
        ITS CORPORATE AFFILIATES (JOINTLY), WHETHER IN CONTRACT, WARRANTY, TORT
        (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), OR
        INFRINGEMENT OR OTHER THEORY, ARISING OUT OF OR IN ANY WAY RELATED TO
        THIS AGREEMENT, THE NODE SOFTWARE, THE VALIDATOR NETWORK, THE
        MASTERNODES OR THE USE OF, OR INABILITY TO USE, THE MASTERNODES, EXCEED
        US $100.
        <br />
        <br />
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY CLAIM OR CAUSE OF
        ACTION ARISING OUT OF OR RELATING TO THE PROGRAM OR THIS AGREEMENT MUST
        BE FILED WITHIN ONE (1) YEAR AFTER THE CLAIM OR CAUSE OF ACTION ACCRUED
        OR IT WILL BE FOREVER BARRED.
        <br />
        <br />
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.indemnification.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.indemnification.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        To the fullest extent permitted by applicable law, you agree to defend,
        indemnify, and hold harmless Morpheus.Network and its and their
        respective officers, directors, employees, contractors, agents,
        licensors, suppliers, successors and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys’ fees) arising out of
        or relating to: (i) your use of the Node Software; (ii) your
        participation in the Program; or (iii) your violation of this Agreement.
        Morpheus.Network reserves the right to exercise sole control over the
        defense of any claim subject to such indemnification at your expense.
        This indemnity is in addition to, and not in lieu of, any other
        indemnities set forth in a written agreement between you and
        Morpheus.Network.
        <br />
        <br />
        If the Node Software becomes, or in Morpheus.Network’s reasonable
        judgment is likely to become, the subject of a claim of infringement,
        then Morpheus.Network may in its sole discretion: (a) obtain the right
        for Node Operator to continue using the Node Software; (b) provide a
        non-infringing functionally equivalent replacement; or (c) modify the
        Node Software so that it is no longer infringing. If Morpheus.Network,
        in its sole and reasonable judgment, determines that none of the above
        options are commercially reasonable, then Morpheus.Network may, without
        liability, suspend or terminate Node Operator’s use of the Node
        Software.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.assignment.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.assignment.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        You shall not assign all or any portion of this Agreement to any third
        party without Morpheus.Network’s prior written consent in its sole
        discretion. Any assignment in violation hereof shall be null and void.
        This Agreement shall be binding on and inure to the benefit of the
        parties and their respective successors and permitted assigns. This
        Agreement is intended solely for the benefit of the parties and is not
        intended to confer third-party beneficiary rights upon any other person
        or entity.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.governingLawAndJurisdiction.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.governingLawAndJurisdiction.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        All matters relating to the Program and this Agreement, and any dispute
        or claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), shall be governed by and construed
        in accordance with the internal laws of Seychelles without giving effect
        to any choice or conflict of law provision or rule (whether of
        Seychelles or any other jurisdiction).
        <br />
        <br />
        Any legal suit, action or proceeding arising out of, or related to, this
        Agreement or the Program shall be instituted exclusively in the courts
        of Seychelles although Morpheus.Network retains the right to bring any
        suit, action or proceeding against you for breach of this Agreement in
        your country of residence or any other relevant country. You waive any
        and all objections to the exercise of jurisdiction over you by such
        courts and to venue in such courts.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.relationship.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.relationship.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        The parties to this Agreement are independent contractors. No agency,
        partnership, franchise, joint venture, or employment relationship is
        intended or created by this Agreement. Neither party has the power or
        authority to create or assume any obligation, or make any
        representations or warranties, on behalf of the other party.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.waiverAndSeverability.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.waiverAndSeverability.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        No waiver by Morpheus.Network of any term or condition of this Agreement
        shall be deemed a further or continuing waiver of such term or condition
        or a waiver of any other term or condition, and any failure of
        Morpheus.Network to assert a right or provision under this Agreement
        shall not constitute a waiver of such right or provision.
        <br />
        <br />
        If any provision of this Agreement is held by a court or other tribunal
        of competent jurisdiction to be invalid, illegal or unenforceable for
        any reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of this Agreement will
        continue in full force and effect.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.survival.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.survival.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        The rights and obligations contained in this Agreement, which by their
        nature require performance following termination, shall survive any
        termination or expiration of this Agreement.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.entireAgreement.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.entireAgreement.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        This Agreement constitutes the sole and entire agreement between you and
        Morpheus.Network with respect to the Program and supersedes all prior
        and contemporaneous understandings, agreements, representations, and
        warranties, both written and oral, with respect to the Program.
      </Paragraph>

      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.notice.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.notice.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          Notices to Morpheus.Network made under this Agreement must be sent via
          email to{' '}
          <a href={`mailto:${Emails.InfoMorpheusNetwork}`}>
            info@morpheus.network
          </a>{' '}
          You agree to receive electronically all communications, agreements,
          documents, notices, and disclosures that we provide in connection with
          the Program (“<b>Communications</b>”). We may provide Communications
          in a variety of ways, including by e-mail, text, in-app notifications,
          or by posting them on{' '}
          <a
            href={ExternalLinks.MorpheusNetworkRoot}
            rel="noreferrer"
            target={'_blank'}
          >
            https://morpheus.network
          </a>
          ,{' '}
          <a
            href={ExternalLinks.TrustSupplyRoot}
            rel="noreferrer"
            target={'_blank'}
          >
            https://trust.supply
          </a>{' '}
          or otherwise made available in conjunction with the Validator Network
          or Node Software. You agree that all Communications that we provide to
          you electronically satisfy any legal requirement that such
          communications be in writing.
        </span>
      </Paragraph>
      <Title
        id={TERMS_TABLE_OF_CONTENT_BY_ID.rulesOfQuest.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.rulesOfQuest.title}
      </Title>
      <Paragraph sx={{ marginBottom: '24px' }}>
        NO PURCHASE OR OTHER FORM OF CONSIDERATION NECESSARY TO ENTER OR WIN.
      </Paragraph>
      <Paragraph sx={{ marginBottom: '24px' }}>
        A PURCHASE OR OTHER FORM OF CONSIDERATION WILL NOT IMPROVE YOUR CHANCES
        OF WINNING.
      </Paragraph>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          These Official Rules (these (“<b>Communications</b>”) shall apply to
          the Trust.Supply Quest Competition (the “<b>Competition</b>”) being
          conducted by MN Consulting Services Inc., a Canadian corporation (“
          <b>MNCS</b>”, “<b>we</b>”, “<b>our</b>” or “<b>us</b>”).
        </span>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.quest_acceptanceRules.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.quest_acceptanceRules.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          By entering the Competition, you (the “<b>Participant</b>”):
        </span>
        <ol>
          <li>agree to be bound by these Rules;</li>
          <li>
            <span>
              acknowledge that you have read the Morpheus.Network Privacy Policy
              available at{' '}
              <a
                href={ExternalLinks.MorpheusNetworkPrivacyPolicy}
                rel="noreferrer"
                target={'_blank'}
              >
                https://morpheus.network/privacy-policy/
              </a>{' '}
              (the “<b>Privacy Policy</b>”) and agree to have any personal
              information that you provide to MNCS controlled and processed in
              accordance with the Privacy Policy;
            </span>
          </li>
          <li>acknowledge that you are at least 18 years of age;</li>
          <li>
            acknowledge that you are not an officer, director, employee, or
            contractor of MNCS nor an immediate family member (defined as
            parents, siblings, children and spouses, regardless of where they
            reside) of any officer, director, employee, or contractor of MNCS;
          </li>
          <li>
            consent to the use of your name, image, and likeness for MNCS and
            its affiliates’ promotional use without further payment or
            compensation to you;
          </li>
          <li>
            grant MNCS, its affiliates and sub-licensees a non-exclusive,
            royalty-free, perpetual, worldwide, irrevocable, and sub-licensable
            license to use, reproduce, modify, adapt, publish, and display any
            materials that you enter into the Competition by performing any
            Quests as further defined below (collectively your “Entry”) for any
            purpose, including future promotional, marketing or publicity
            purposes, in any media, without compensation, restriction on use,
            attribution or liability;
          </li>
          <li>
            grant MNCS permission to publish or make available the surname and
            region of each winner as required by law;
          </li>
          <li>
            agree that you are fully responsible for any materials included in
            your Entry;
          </li>
          <li>
            agree that, in those instances where an Entry includes any sort of
            artwork, everything in your Entry is your original artistic work
            that does not infringe any rights of any third party, including, but
            not limited to rights in copyright;
          </li>
          <li>
            agree that you will obtain prior consent and agreement to these
            Rules from the owner of any intellectual property contained in your
            Entry;
          </li>
          <li>
            agree that MNCS may remove, decline to publish, or invalidate any
            Entry without notice for any reason whatsoever;
          </li>
          <li>
            agree that you will not tamper or attempt to tamper with the entry
            process or the operation of the Competition;
          </li>
          <li>
            agree that you will not submit any Entry by proxy or any
            electronic/automated means such as script, macro or use of any
            automated, robotic, repetitive, programmed or similar entry methods
            or any other similar software or program;
          </li>
          <li>
            agree that you will not act in an unsportsmanlike or disruptive
            manner, or with the intent to annoy, abuse, threaten or harass any
            other person;
          </li>
          <li>
            warrant and agree that you will not submit any Entry that:
            <ul>
              <li>is unlawful or fraudulent;</li>
              <li>
                is in breach or alleged breach of any intellectual property,
                privacy, publicity, or other rights, or otherwise inappropriate;
              </li>
              <li>
                is in “bad taste” or otherwise damages MNCS’s brand image;
              </li>
              <li>
                is sexually explicit or offensive as determined by MNCS in its
                sole and absolute discretion;
              </li>
            </ul>
          </li>
          <li>
            agree that you will comply with all applicable laws and regulations,
            including those governing copyright, content, defamation, privacy,
            publicity and the access or use of others’ computers or
            communication systems;
          </li>
          <li>agree to indemnify MNCS for any breach of the Rules; and</li>
          <li>
            warrant that you are not a resident of any of the countries listed
            as Prohibited Jurisdictions below.
          </li>
        </ol>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questProhibitedJurisdictions.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questProhibitedJurisdictions.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        Residents from the following countries are prohibited from participating
        in the Competition:
        <ul>
          <li>Brazil</li>
          <li>Croatia</li>
          <li>Egypt</li>
          <li>Estonia</li>
          <li>Italy</li>
          <li>Iran</li>
          <li>North Korea</li>
          <li>Philippines</li>
          <li>Russia</li>
          <li>Saudi Arabia</li>
          <li>Singapore</li>
          <li>United Arab Emirates</li>
        </ul>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questCompetitionPeriod.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questCompetitionPeriod.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          The start and end times for the Competition (the “
          <b>Competition Period</b>”) are as follows:
        </span>
        Competition Start Time: 10/09/2024
        <br />
        Competition End Time: 10/03/2025
        <br />
        MNCS may extend the Competition Period in its sole discretion.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questRegistration.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questRegistration.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        To participate in the Competition, you must perform all of the following
        registration steps:
        <ol>
          <li>Register an account on Trust.Supply;</li>
          <li>Connect your MetaMask wallet;</li>
          <li>Connect your X (Twitter) account;</li>
          <li>Opt in to receive messages and emails;</li>
          <li>
            Agree to these Rules and the Privacy Policy when those options are
            made available to you.
          </li>
        </ol>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.quests.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.quests.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          In order to be eligible for rewards, you must either perform
          activities, such as liking and reposting content on X (Twitter)
          (collectively referred to as “<b>Quests</b>”) to earn activity points
          (“
          <b>Activity Points</b>”) or submit an Entry as described in the
          section below with the heading “No Purchase Necessary.”
        </span>
        <br />
        <span>
          If you wish to complete Quests to earn Activity Points, you must visit
          Trust.Supply regularly and click on the various Quests as they become
          available to you. Activity Points are earned by following the
          instructions attached to each Quest. Once you have completed all
          available activities within each Quest, select “Verify” to earn
          Activity Points, which will then accumulate during each Season, as
          described below.
        </span>
        <br />
        <span>
          <b>
            Note that due to limitations in the X (Twitter) API, if you fail to
            complete all of the activities prior to selecting “Verify,” your
            dashboard may show that the Quest has been completed but you will
            not be awarded Activity Points, so be sure to complete ALL
            activities before selecting “Verify.”
          </b>
        </span>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questSeasons.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questSeasons.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          Each season of Quests (a “<b>Season</b>”) will run for approximately
          one month, with the exact start and end times provided on the
          Trust.Supply website. During each Season, you can continue to
          accumulate Activity Points by completing Quests. At the end of every
          Season, the total number of Activity Points you have accumulated will
          be used to determine which Bracket and Prize Pool (as defined below)
          you will be eligible for in that particular Season.
        </span>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questBrackets.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questBrackets.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        Currently there is a single bracket that you may be eligible for, this
        may change with expanded brackets being added depending on the number of
        participants during each phase. Eligibility for brackets is dependent on
        the Activity Points you have accumulated in each Season as defined
        below:
        <ol>
          <li>
            <b>Top 20 Bracket</b>: The top 20 participants who have accumulated
            the most Activity Points in a given Season.
          </li>
        </ol>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questPrizePools.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questPrizePools.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        All participants who meet the eligibility requirements for each of the
        Brackets described above will either receive a reward or be entered into
        a random drawing to receive a reward as described below:
        <ol>
          <li>
            <b>Top 20 Bracket</b>: All participants who qualify for the Top 20
            Bracket will be entered to win 1 of 5 prizes from a pool of MNW TBD
            dependent on participation thresholds.
          </li>
          <li>
            Currently the variable prize pool starts at 600 MNW for up to 1000
            participants and up to 2000 MNW for up to 5000 participants.
          </li>
        </ol>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questParticipateOnMobile.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questParticipateOnMobile.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        If you opt to participate in the Competition via a wireless mobile
        device, data rates may apply for any message sent or received according
        to the terms and conditions of your service agreement with your wireless
        carrier. Other charges may apply (such as normal airtime and carrier
        charges as well as charges for wireless Internet access) and may appear
        on your mobile phone bill or be deducted from your prepaid account
        balance. Wireless carrier rate plans may vary, and you should contact
        your wireless carrier for more information on messaging rate plans and
        charges relating to your participation in the Competition. Wireless
        mobile device service (phone and/or data) may not be available in all
        areas. Consult the manual for your wireless mobile device for specific
        instructions. MNCS is not responsible for any charges or lack of
        functionality of wireless mobile devices. If you opt to access the
        Competition via a third-party’s wireless mobile device, you represent
        and warrant that you have permission from the device owner (and
        associated wireless account subscriber if different than the device
        owner) to do so and that you have advised such person(s) of the
        provisions of this paragraph which apply to them as a result of your use
        of their wireless mobile device. MNCS expressly disclaims any and all
        liability related to your use of a third-party’s wireless mobile device
        (or other device) to access or participate in the Competition or to
        access any related online content.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questNoPurchaseNecessary.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questNoPurchaseNecessary.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        NO PURCHASE OR CONSIDERATION OF ANY KIND IS NECESSARY TO ENTER OR WIN
        THE COMPETITION. A PURCHASE OR PAYMENT WILL NOT INCREASE YOUR CHANCES OF
        WINNING. YOU ARE NOT REQUIRED TO BE A CUSTOMER OF MNCS ALTHOUGH
        CUSTOMERS OF MNCS ARE WELCOME TO ENTER. THE COMPETITION IS VOID WHERE
        PROHIBITED BY APPLICABLE LAW OR REGULATION.
        <br />
        <br />
        To enter the Competition without completing any Quests, mail a postcard
        with your handwritten full legal name, age, mailing address, email
        address (or other contact method), wallet address and the name of the
        single randomly selected prize pool in which you would like your Entry
        to be included (i.e., TOP 100 LEGEND, LEGEND, TOP 100 ELITE, ELITE,
        CHAMPION or COLLABORATOR) to: Trust.Supply Quest Competition Entries, ℅
        MNCS, 4145 N Service Rd 2nd Floor Burlington, ON L7L 6A3
        <br />
        <br />
        Postcard entries shall be included in the random drawing for the prize
        pool available for the Season in which the postcard is received. Only
        one postcard Entry is permitted per person per prize pool per Season.
        Any attempt to submit more than one such Entry per person shall
        disqualify the entrant from being entered into any of the associated
        drawings.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questNotification.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questNotification.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        MNCS’s decisions on all matters relating to the Competition (including
        but not limited to registration and determination of the winners, the
        awarding of any prizes and the operation of the Competition) will be
        final and no correspondence will be entered into regarding any of the
        foregoing.
        <br />
        <br />
        Winners who have entered the Competition by completing Quests shall be
        notified automatically either when they log into the Trust.Supply
        website or via email or other method of notification supplied by the
        winning participant
        <br />
        <br />
        Winners who have entered the Competition via postcard Entry shall be
        notified as soon as practicable via the method of communication provided
        to MNCS on their winning postcard Entry.
        <br />
        <br />
        If a winner cannot be contacted or does not respond within 7 days from
        the date on which MNCS first notifies them using the contact information
        provided, MNCS reserves the right to withdraw the applicable prize (in
        which case the winner will be deemed to have forfeited the prize) and to
        award it to another participant. MNCS will publish the winners’ names on
        the Trust.Supply website.
        <br />
        <br />
        Winners are responsible for any and all applicable federal, provincial,
        territorial, local or foreign taxes (and the reporting thereof), if any,
        and for all other unspecified expenses, fees, charges, or duties
        associated with acceptance or use of any prize. It is the individual
        winners’ responsibility to understand and abide by any federal,
        provincial, territorial, local or foreign tax laws that may apply to
        receipt of any prize.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questDisqualification.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questDisqualification.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        MNCS reserves the right to disqualify you for any of the following
        reasons, without any further liability to you:
        <ol>
          <li>
            Using multiple X (Twitter) or other social media accounts to submit
            multiple entries.
          </li>
          <li>
            Posting duplicate or near-duplicate entries from the same X
            (Twitter) or other social media accounts.
          </li>
          <li>
            Failing to meet all of the requirements as indicated in the Rules or
            otherwise violating any of these Rules.
          </li>
        </ol>
        In the event that any winner is disqualified by MNCS, any prize(s) won
        by the winning participant may be forfeited and MNCS reserves the right
        to reclaim any prize(s) already distributed to the winning participant.
        All participants must comply with any directions given to them by MNCS
        including but not limited to any and all relevant laws, rules and
        applicable regulatory codes.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questDisclaimers.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questDisclaimers.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        The Competition is in no way sponsored, endorsed, or administered by, or
        associated with X Corp. or any other social media company.
        <br />
        <br />
        MNCS reserves the right, in its sole discretion, to cancel, modify or
        suspend the Competition if any fraud or any other factor impairs the
        integrity or proper functioning of the Competition, or for any other
        reason whatsoever without liability. In the event of termination of the
        Competition, MNCS may award any prizes from among the eligible entries
        received up to the time of the suspension or termination, whichever date
        occurred first.
        <br />
        <br />
        In the event that MNCS receives an insufficient number of entries that
        meet the necessary criteria appearing in these Rules and/or MNCS is
        prevented from awarding any prizes or continuing with the Competition as
        contemplated herein by any event beyond its control, then MNCS shall
        have the right to modify, suspend, or terminate the Competition. If the
        Competition is terminated before the designated end date, MNCS will (if
        possible) judge all entries that meet the necessary criteria appearing
        in the Announcement received as of the date of the event giving rise to
        the termination.
        <br />
        <br />
        Any attempt by you or any other individual to undermine the legitimate
        operation of this Competition may be a violation of criminal and/or
        civil laws. Should any such attempt be made, MNCS reserves the right to
        seek remedies and damages to the fullest extent permitted by law.
        <br />
        <br />
        Neither MNCS nor its subsidiaries or affiliates, nor any of their
        respective representatives are responsible for (a) theft, alteration,
        destruction or unauthorized access to entries; (b) problems downloading
        or uploading any Competition-related information or data to or from any
        website or platform; (c) any other technical failures or malfunctions
        of, or experienced by, any electronic equipment, computer systems
        (including, without limitation, hardware, software, SaaS or other
        platforms, including, without limitation, any social media platform),
        servers, or service or other providers, phone lines, the public
        internet; or (d) failure of any electronic mail entry to be received by
        MNCS on account of technical problems, traffic, congestion on the
        internet or any website or platform, or any other technical problems
        related to website or platform entries including telecommunication
        miscommunication or failure, and failed, lost, delayed, incomplete,
        garbled or misdirected communications which may limit your ability to
        participate in this Competition.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questLimitationOfLiability.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questLimitationOfLiability.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          To the extent permitted by law, MNCS and its affiliates and each of
          their respective directors, officers, employees, contractors,
          representatives, and agents, including without limitation any judges
          of the Competition (together, the “<b>Representatives</b>,” and
          together with MNCS and its affiliates, the “<b>Released Parties</b>”)
          shall not be liable in any way (including, without limitation, in
          negligence), for any loss or damage whatsoever suffered (including,
          without limitation, direct, indirect or consequential loss, or the
          right to have damages multiplied or increased) or for any loss or
          damage or personal injury suffered or sustained in connection with the
          Competition, or acceptance, possession, or use or misuse of any
          prizes, and you hereby release the Released Parties from any claims or
          damages arising out of or related to your participation and/or entry
          in the Competition and/or your receipt, acceptance, possession or use
          or misuse of any prizes awarded in this Competition. For the avoidance
          of doubt, and without prejudice to the generality of the foregoing,
          you release the Released Parties from any loss, damages, or cause of
          action in relation to (a) any technical errors associated with the
          Competition; (b) unauthorized human intervention in the Competition;
          and (c) any other errors or problems in connection with the
          Competition, including, without limitation, errors that may occur in
          the administration of the Competition.
        </span>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questGoverningLawAndJurisdiction.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questGoverningLawAndJurisdiction.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        All matters relating to the Competition and these Terms, and any dispute
        or claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), shall be governed by and construed
        in accordance with the internal laws of Canada without giving effect to
        any choice or conflict of law provision or rule (whether of Canada or
        any other jurisdiction).
        <br />
        <br />
        Any legal suit, action or proceeding arising out of, or related to,
        these Rules or the Competition shall be instituted exclusively in the
        courts of Canada although we retain the right to bring any suit, action
        or proceeding against you for breach of these Rules in your country of
        residence or any other relevant country. You waive any and all
        objections to the exercise of jurisdiction over you by such courts and
        to venue in such courts.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questArbitration.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questArbitration.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        <span>
          {' '}
          In our sole discretion, we may require you to submit any disputes
          relating to the Competition or these Rules—including including
          disputes arising from or concerning their interpretation, violation,
          invalidity, non-performance, or termination—to mediation then
          arbitration (if necessary) in accordance with the International Centre
          for Dispute Resolution Canada Procedures (available online at{' '}
          <a href={ExternalLinks.IcdrCanada} rel="noreferrer" target={'_blank'}>
            https://www.icdr.org/icdrcanada
          </a>{' '}
          ) in force at the time of the arbitration or mediation.
        </span>
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questLimitation.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questLimitation.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THE COMPETITION OR THESE RULES MUST BE COMMENCED WITHIN ONE (1) YEAR
        AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
        CLAIM IS PERMANENTLY BARRED.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questWaiverAndSeverability.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questWaiverAndSeverability.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        No waiver by MNCS of any term or condition set forth in these Rules
        shall be deemed a further or continuing waiver of such term or condition
        or a waiver of any other term or condition, and any failure of MNCS to
        assert a right or provision under these Rules shall not constitute a
        waiver of such right or provision.
        <br />
        <br />
        If any provision of these Rules is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal, or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of the Rules will continue in
        full force and effect.
      </Paragraph>
      <Subtitle
        id={TERMS_TABLE_OF_CONTENT_BY_ID.questEntireAgreement.id}
        sx={{ marginBottom: '24px' }}
      >
        {TERMS_TABLE_OF_CONTENT_BY_ID.questEntireAgreement.title}
      </Subtitle>
      <Paragraph sx={{ marginBottom: '48px' }}>
        These Rules and our Privacy Policy constitute the sole and entire
        agreement between you and MNCS with respect to the Competition and
        supersede all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, with respect to
        the Competition.
      </Paragraph>
    </Container>
  )
}

export default TermsLayout
